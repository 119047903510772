import React, { Component } from 'react';
import CarouselRow from 'components/carousel/CarouselRow';
import CarouselRowNoSlider from 'components/carousel/CarouselRowNoSlider';

import { NEW_STYLE_ENABLED } from 'constants/App';

class CarouselBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isReady, blocks, copy, siteStyles, isSmartphone, ageRating, rightPadStyle, spinner, isRTL, carouselPlaceholder, carouselBreakpoints } = this.props; // passed through
    let previousColour = '';

    if (NEW_STYLE_ENABLED) {
      const groups = {};
      const groupedBlocks = blocks.map((data, index) => {
        const first = index === 0;
        const last = index === blocks.length - 1; // Math.max(0, index === blocks.length - 1);
        const currentColour = data.colour;
        const nextColour = blocks[index + 1] && blocks[index + 1].colour;

        let isNewColourBlock = false;
        let isNewColourBlockEnd = false;

        if (previousColour !== currentColour) {
          previousColour = currentColour;
          isNewColourBlock = true;
        }
        if (currentColour !== nextColour) {
          isNewColourBlockEnd = true;
        }

        if ((isNewColourBlock && isNewColourBlockEnd) || (isNewColourBlock && !isNewColourBlockEnd)) {
          groups[`${currentColour}_${index}`] = [];
          groups[`${currentColour}_${index}`].push(data);
        } else {
          groups[Object.keys(groups)[Object.keys(groups).length - 1]].push(data);
        }
      });

      const renderCarousel = (data) => {
        const opts = {
          key: data.id,
          isReady,
          copy,
          isNewColourBlock: false,
          isNewColourBlockEnd: false,
          siteStyles,
          ageRating,
          rightPadStyle,
          spinner,
          isRTL,
          carouselPlaceholder,
          carouselBreakpoints,
          ...data,
        };

        return data.itemsById.length > 0 && !isSmartphone ? <CarouselRow {...opts} /> : data.itemsById.length > 0 ? <CarouselRowNoSlider {...opts} /> : null;
      };

      return Object.entries(groups).map((data, index) => {
        const { 0: name, 1: dataBlocks } = data;
        return (
          <div className={`block_section ${name.split('_')[0]}_block`} key={index}>
            <div className="character-image-holder"></div>
            {dataBlocks.map((block, index) => {
              return renderCarousel(block);
            })}
          </div>
        );
      });
    }

    const renderCarousels = blocks.map((data, index) => {
      const first = index === 0;
      const last = index === blocks.length - 1; // Math.max(0, index === blocks.length - 1);
      const currentColour = data.colour;
      const nextColour = blocks[index + 1] && blocks[index + 1].colour;

      let isNewColourBlock = false;
      let isNewColourBlockEnd = false;

      if (previousColour !== currentColour) {
        previousColour = currentColour;
        isNewColourBlock = true;
      }
      if (currentColour !== nextColour) {
        isNewColourBlockEnd = true;
      }

      const opts = {
        key: data.id,
        isReady,
        copy,
        index,
        first,
        last,
        isNewColourBlock,
        isNewColourBlockEnd,
        siteStyles,
        ageRating,
        rightPadStyle,
        spinner,
        isRTL,
        carouselPlaceholder,
        carouselBreakpoints,
        ...data,
      };

      return data.itemsById.length > 0 && !isSmartphone ? <CarouselRow {...opts} /> : data.itemsById.length > 0 ? <CarouselRowNoSlider {...opts} /> : null;
      // return data.itemsById.length > 3 ? <CarouselRow {...opts} /> : data.itemsById.length > 0 ? <CarouselRowNoSlider {...opts} /> : null;
    });

    return <>{renderCarousels}</>;
  }
}

export default CarouselBlock;
