import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBlockItems } from 'store/carousels/carouselsSelectors';
import CarouselItemApp from 'components/carousel/CarouselItemApp';
import CarouselItemOther from 'components/carousel/CarouselItemOther';
import { Title } from 'components/carousel/Title';
import CarouselItemPlaceholder from 'components/carousel/CarouselItemPlaceholder';
import { USE_WEBP } from 'constants/App';

/**
 * Smartphone only
 * On mobile nop swiper is used
 * 3 items max per carousel are rendered stacked
 */
class CarouselRowNoSlider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isReady, siteStyles, ageRating, rightPadStyle, spinner, carouselPlaceholder } = this.props; // passed through
    const { index, ribbon, title, first, last, blocks, copy, colour, isNewColourBlock, isNewColourBlockEnd } = this.props;
    const { home_new_content_title: isNewLabel } = copy;

    const carouselItemClass = 'carousel_item platform_all';

    const renderItems = blocks.map((data, index) => {
      if (data.platforms) {
        return <CarouselItemApp key={data.id} isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass={carouselItemClass} />;
      } else {
        return <CarouselItemOther key={data.id} isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass={carouselItemClass} />;
      }
    });

    const groupColourBlocks = siteStyles.groupColourBlocks;
    const blockColour = siteStyles.colours.carousel === 'cms' ? 'b_' + colour : siteStyles.colours.carousel;
    const extraClass = USE_WEBP ? 'webp' : 'non-webp';

    let clzz = `wrapper ${blockColour}`;
    if (first) {
      clzz += ' wrapper--radius-top--margin-top';
    }
    if (last) {
      clzz += ' wrapper--radius-bottom';
    }
    if (ribbon) {
      clzz += ' wrapper--ribbon';
    }

    let blockClass = `block-list ${colour}`;
    if (index === 0) {
      blockClass += ' block-list--first';
    }
    if (groupColourBlocks) {
      if (isNewColourBlock) {
        blockClass += ' margin_top';
      }
      if (!isNewColourBlockEnd) {
        blockClass += ' same_block_colour';
      }
    }

    const renderCarousel = isReady;

    return !renderCarousel ? (
      <section className={blockClass}>
        <div className={clzz} style={rightPadStyle}>
          {<Title ribbon={ribbon} title={title} extraClass={extraClass} />}
          <div className="carousel_placeholder">
            <CarouselItemPlaceholder spinner={spinner} carouselPlaceholder={carouselPlaceholder} />
            <CarouselItemPlaceholder spinner={spinner} carouselPlaceholder={carouselPlaceholder} />
            <CarouselItemPlaceholder spinner={spinner} carouselPlaceholder={carouselPlaceholder} />
          </div>
        </div>
      </section>
    ) : (
      <section className={blockClass}>
        <div className={clzz} style={rightPadStyle}>
          {<Title ribbon={ribbon} title={title} extraClass={extraClass} />}
          <div className="carousel_widget three_onscreen">
            <div className="carousel_area">
              <div className="carousel_wrapper_ignore">{renderItems}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    blocks: getBlockItems(state, ownProps.itemsById),
  };
}

export default connect(mapStateToProps, null)(CarouselRowNoSlider);
