import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Index from 'pages/Index';
import { LOG, FRIENDLY_URLS } from 'constants/App';

class AppRouter extends Component {
  constructor(props) {
    super(props);
  }

  /*
   * TODO:
   *  1. DONE, needs testing : Handle the back button stepping through history
   *  2. DONE : Reflect selected category in category bar
   *  3. DONE Add default category to Games page
   *  4. DONE : Fix more button rendering when it shouldn't : https://turnerboom.dev.imac/games : select iOS in filter block
   *
   * FIXED - TEST
   * fetchCarouselService firing twice
   * fetchPageEntries firing twice
   *
   * This is happening because the Content/Carousel components are mounting and unmounting and
   * this is making the components initialised state false, triggering a fetchEntries in componentDidUpdate
   *
   * 1. Content/Carousel componentDidUpdate uses this.props.fetchEntries(); This was setup to fire
   * after the component enters viewport using ObserveOnce.
   * (!this.state.initialised && this.props.hasBeenVisible)
   *
   * 2. AppActions.updatePageId also fetches entries - this is triggered when the browser url changes via
   * a game category click or browser button
   *
   * Maybe store the Content/Carousel components initialised state in a store so that it is only triggered once
   *
   */
  render() {
    const { pathname } = this.props.location;

    if (!FRIENDLY_URLS.game || FRIENDLY_URLS.game === '') {
      console.warn('AppRouter :: missing game friendly url tag');
    }
    if (!FRIENDLY_URLS.category || FRIENDLY_URLS.category === '') {
      console.warn('AppRouter :: missing category friendly url tag');
    }
    const gameTag = FRIENDLY_URLS?.game ?? 'games';
    const categoryTag = FRIENDLY_URLS?.category ?? 'category';

    let gamesPath = `/${gameTag}`;
    let gamesCategoryPath = `/${gameTag}/${categoryTag}/:category`;
    if (pathname.includes('/en/')) {
      gamesPath = '/en' + gamesPath;
      gamesCategoryPath = '/en' + gamesCategoryPath;
    } else if (pathname.includes('/ar/')) {
      gamesPath = '/ar' + gamesPath;
      gamesCategoryPath = '/ar' + gamesCategoryPath;
    }
    if (LOG.enabledStore && pathname.includes('/games')) {
      console.log(`%c@@@@@@@@@@@@@@@ AppRouter :: pathname ${pathname} | gamesPath ${gamesPath} | gamesCategoryPath ${gamesCategoryPath}`, LOG.clrGreen);
    }

    return (
      <React.Fragment>
        <Switch location={this.props.location}>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route strict path={gamesCategoryPath} render={(props) => <Index {...props} isGamePage={false} isGameCategoryPage={true} />} />
          <Route strict path={gamesPath} render={(props) => <Index {...props} isGamePage={true} isGameCategoryPage={false} />} />
          <Route render={(props) => <Index {...props} isGamePage={false} isGameCategoryPage={false} />} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default withRouter(AppRouter);
