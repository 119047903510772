/* global __WATCH__:false */

import { DEVICE, DYNAMIC_URL, FRONTEND_URL, LANGUAGE_TEXTS, PLACEHOLDER, USE_16_9_THUMBNAILS, LOG } from 'constants/App';
import { isNewContent } from 'utils/date';
import { parseEntryContentType } from 'store/models/EntryParserFactory';
import { CONTENT_TYPE, CONTENT_TYPE_PREPEND_IDS } from 'constants/Const';

const parseImageData = (id, name, data, promo_image_small, promo_image_medium, promo_image_large) => {
  const { thumbnail_size = false, thumbnail_image_1920 = false, thumbnail_image_1600 = false, thumbnail_image_1280 = false } = data;
  let src_set = {
    img_small: DYNAMIC_URL + promo_image_small,
    img_medium: DYNAMIC_URL + promo_image_medium,
    img_large: DYNAMIC_URL + promo_image_large,
  };

  let isFallbackImage = !thumbnail_size;

  // force fallback only if !USE_16_9_THUMBNAILS
  isFallbackImage = USE_16_9_THUMBNAILS ? isFallbackImage : true;

  if (!isFallbackImage) {
    let thumbnailsSizes = data[`thumbnail_image_${thumbnail_size}`];
    if (thumbnailsSizes) {
      const urls = thumbnailsSizes.map((size) => {
        return size.file ? DYNAMIC_URL + size.file : size.file_name ? DYNAMIC_URL + size.file_name : '';
      });
      urls.length === 4 && urls.shift(); // if length equals 4 then first one is original 1600/1280 size which we dont need
      const large_image = urls[0] || '';
      const medium_image = urls[1] || '';
      const small_image = urls[2] || '';

      src_set = {
        img_small: small_image,
        img_medium: medium_image,
        img_large: large_image,
      };
    } else {
      console.warn(`thumbnailsSizes missing for :: show id ${id} | name ${name} -- using thumbnail fallback`);
      isFallbackImage = true;
    }
  }

  if (!isFallbackImage) {
    LOG.enabledStore && console.log(`%cparseImageData | ${thumbnail_size} :: show id ${id} | name ${name}`, 'background: #666; color: white');
  } else {
    LOG.enabledStore && console.log(`%cparseImageData | using fallback promo thumnbnail | show id ${id} | name ${name}`, 'background: #ccc; color: black', data);
  }
  return { src_set, isFallbackImage };
};

export const parseURLForRedirect = (url, url_internal) => {
  if (url_internal) {
    return url;
  } else {
    return FRONTEND_URL + 'redirects/' + btoa(url);
  }
};

/**
 * parseAppEntry
 * This is used for the filtered content blocks. see @store/entriesActions -> parseApps
 * filtered content blocks parses any apps:
 * Once into entries.items.byID and entries.items.allIds - to track the actual content count
 * Once for each platform type into entries.apps.byID and entries.apps.allIds - id is appended with platform type eg:
 *    apps.allIds: (2) ["ap_44_android", "ap_44_ios"]
 *    apps.byID: {ap_44_android: {…}, ap_44_ios: {…}}
 *
 *    entries.items.allIds: (2) ["ap_44"]
 *    entries.items.byID: {ap_44: {…}}
 * This allows filtering on iOS or Android (or any future platforms)
 */
export const parseAppEntry = (content_type, o) => {
  const {
    id,
    show,
    name,
    data,
    published_date,
    promo_image_small,
    promo_image_medium,
    promo_image_large,
    promo_image_large_width,
    promo_image_large_height,
    promo_image_text,
    url_internal = true,
  } = o || {};
  const title = show ? show.name : name;
  const description = o.title;
  const englishname = o.name_english;
  const alt_tag = promo_image_text || title;
  const showdata = o.show || {};

  const { src_set, isFallbackImage } = parseImageData(id, name, data, promo_image_small, promo_image_medium, promo_image_large);

  if (!content_type) {
    console.warn('EntryParser -> parseAppEntry :: content_type is missing for ', o);
    content_type = CONTENT_TYPE.EDITORIAL;
  }
  let platforms = [];
  const { store_links, tracking_id } = o;

  const prependedId = CONTENT_TYPE_PREPEND_IDS[content_type] + id; // id's are only unique to content_type, so need to prepend content type to id

  const template = LANGUAGE_TEXTS.content_app_badge_attribute_title || '{app-title}';
  const titleAttribute = template.includes('{app-title}') ? template.replace('{app-title}', name) : name;

  for (const obj of store_links) {
    const { store, link } = obj;
    const { platform } = store;
    const entryData = {
      id: prependedId + '_' + platform,
      content_type,
      title,
      description,
      published_date,
      isFallbackImage,
      ...src_set,
      img_placeholder: PLACEHOLDER,
      img_width: promo_image_large_width,
      img_height: promo_image_large_height,
      isNewContent: published_date ? isNewContent(published_date) : false,
      platform,
      link: parseURLForRedirect(link, url_internal),
      icon: DYNAMIC_URL + store.image,
      amc_store: store.name,
      amc_title: tracking_id,
      amc_redirect: 'self',
      amc_exit_track: link,
      target: '_blank',
      englishname,
      alt_tag,
      titleAttribute,
      showdata,
    };
    platforms.push(entryData);
  }
  // if (LOG.enabledStore) {
  //   for (const platform of platforms) {
  //     console.log(`%c++++++ parseAppEntry [${platform.content_type.toUpperCase()} ${platform.id}]`, LOG.clrBlack, platform);
  //   }
  // }
  return platforms;
};

export const parseEntry = (content_type, o) => {
  const { id, show, name, data, target, published_date, promo_image_small, promo_image_medium, promo_image_large, promo_image_large_width, promo_image_large_height, promo_image_text } = o || {};

  const { src_set, isFallbackImage } = parseImageData(id, name, data, promo_image_small, promo_image_medium, promo_image_large);

  const devices = { desktop: o.desktop_link ? true : false, tablet: o.tablet_link ? true : false, smartphone: o.smartphone_link ? true : false };
  if ((content_type === CONTENT_TYPE.WEB_GAME || content_type === CONTENT_TYPE.QUIZ) && !devices[DEVICE]) {
    return;
  }

  let title = show ? show.name : name;
  if (content_type === CONTENT_TYPE.MANUAL) {
    title = o.title || '';
  }
  const description = o.title;
  const englishname = o.name_english;
  const alt_tag = promo_image_text || title;
  const showdata = o.show || {};

  if (!content_type) {
    console.warn('EntryParser -> parseEntry :: content_type is missing for ', o);
    content_type = CONTENT_TYPE.EDITORIAL;
  }

  const prependedId = CONTENT_TYPE_PREPEND_IDS[content_type] + id; // id's are only unique to content_type, so need to prepend content type to id

  let entryData = {
    id: prependedId,
    content_type,
    title,
    description,
    published_date,
    devices,
    isFallbackImage,
    ...src_set,
    img_placeholder: PLACEHOLDER,
    img_width: promo_image_large_width,
    img_height: promo_image_large_height,
    isNewContent: published_date ? isNewContent(published_date) : false,
    target,
    englishname,
    alt_tag,
    titleAttribute: alt_tag,
    showdata,
  };

  entryData = parseEntryContentType(content_type, entryData, o);
  // LOG.enabledStore && console.log(`%c++++++ parseEntry [${entryData.content_type && entryData.content_type.toUpperCase()} ${entryData.id}]`, LOG.clrBlack, entryData);
  return entryData;
};

export const parseFeatureEntry = (content_type, data) => {
  const o = data[content_type] || data;
  const { id, show, name, target, published_date, promo_image_small, promo_image_medium, promo_image_large, promo_image_large_width, promo_image_large_height, promo_image_text } = o || {};

  const title = show ? show.name : name ? name : o.title;
  const description = o.title;
  const englishname = o.name_english;
  const alt_tag = promo_image_text || title || description;
  const showdata = o.show || {};
  const thumbnailData = o?.data ?? {};

  const { src_set, isFallbackImage } = parseImageData(id, title, thumbnailData, promo_image_small, promo_image_medium, promo_image_large);

  if (!content_type) {
    console.warn('EntryParser -> parseEntry :: content_type is missing for ', o);
    content_type = CONTENT_TYPE.EDITORIAL;
  }

  const isDesktop = o.desktop_link ? true : false;
  const isTablet = o.tablet_link ? true : false;
  const isSmartphone = o.smartphone_link ? true : false;

  const prependedId = CONTENT_TYPE_PREPEND_IDS[content_type] + id; // id's are only unique to content_type, so need to prepend content type to id

  let entryData = {
    id: prependedId,
    content_type,
    title,
    description,
    published_date,
    devices: { desktop: isDesktop, tablet: isTablet, smartphone: isSmartphone },
    isFallbackImage,
    ...src_set,
    img_placeholder: PLACEHOLDER,
    img_width: promo_image_large_width,
    img_height: promo_image_large_height,
    isNewContent: published_date ? isNewContent(published_date) : false,
    target,
    englishname,
    alt_tag,
    titleAttribute: alt_tag,
    showdata,
  };

  entryData = parseEntryContentType(content_type, entryData, o);

  /**
   * BOINGWEB-74 : Editorials can be over-ridden. Override data is in the root of the data object
   */
  if (content_type === CONTENT_TYPE.EDITORIAL) {
    // overrides
    const { title: override_title, short_description, promo_image_large: override_image_large, promo_image_medium: override_image_medium, promo_image_small: override_image_small } = data || {};
    // LOG.enabledStore && console.log(`%c++++++ overrides ${override_title} ${short_description} ${override_image_large}`, LOG.clrBlack);

    entryData.title = override_title ? override_title : entryData.title;
    entryData.description = short_description ? short_description : entryData.description;
    entryData.img_small = override_image_small ? DYNAMIC_URL + override_image_small : entryData.img_small;
    entryData.img_medium = override_image_medium ? DYNAMIC_URL + override_image_medium : entryData.img_medium;
    entryData.img_large = override_image_large ? DYNAMIC_URL + override_image_large : entryData.img_large;
  }
  // LOG.enabledStore && console.log(`%c++++++ parseFeatureEntry [${entryData.content_type.toUpperCase()} ${entryData.id}]`, LOG.clrBlack, entryData);
  return entryData;
};

export const parseRelatedItemEntry = (content_type, data) => {
  const o = data[content_type] || data;
  const { id, show, name, target, published_date, promo_image_small, promo_image_medium, promo_image_large, promo_image_large_width, promo_image_large_height, promo_image_text } = o || {};

  const title = show ? show.name : name;
  const description = o.title;
  const englishname = o.name_english;
  const alt_tag = promo_image_text || title || description;
  const showdata = o.show || {};
  const thumbnailData = o?.data ?? {};

  const { src_set, isFallbackImage } = parseImageData(id, name, thumbnailData, promo_image_small, promo_image_medium, promo_image_large);

  if (!content_type) {
    console.warn(`%c++++++ EntryParser -> parseRelatedItemEntry :: content_type is missing for`, LOG.clrBlack, o);
    content_type = CONTENT_TYPE.EDITORIAL;
  }

  const isDesktop = o.desktop_link ? true : false;
  const isTablet = o.tablet_link ? true : false;
  const isSmartphone = o.smartphone_link ? true : false;

  const prependedId = CONTENT_TYPE_PREPEND_IDS[content_type] + id; // id's are only unique to content_type, so need to prepend content type to id

  let entryData = {
    id: prependedId,
    content_type,
    title,
    description,
    published_date,
    devices: { desktop: isDesktop, tablet: isTablet, smartphone: isSmartphone },
    isFallbackImage,
    ...src_set,
    // img_small: DYNAMIC_URL + promo_image_small,
    // img_medium: DYNAMIC_URL + promo_image_medium,
    // img_large: DYNAMIC_URL + promo_image_large,
    img_placeholder: PLACEHOLDER,
    img_width: promo_image_large_width,
    img_height: promo_image_large_height,
    isNewContent: published_date ? isNewContent(published_date) : false,
    target,
    englishname,
    alt_tag,
    titleAttribute: alt_tag,
    showdata,
  };

  entryData = parseEntryContentType(content_type, entryData, o);

  LOG.enabledStore && console.log(`%c++++++ parseRelatedItemEntry [${entryData.content_type.toUpperCase()} ${entryData.id}]`, LOG.clrBlack, entryData);
  return entryData;
};
