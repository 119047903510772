import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TITLE_FILTER_REPLACEMENT_IDS } from 'constants/Const';

import { batchFetchEntries, fetchEntries, setInitialised } from 'store/entries/entriesActions';
import {
  getDidError,
  getEntries,
  getIsFetchingContentData,
  getIsInitialised,
  getIsReady,
  getMoreEntriesAvailable,
  getPageHasNoEntriesToRequest,
  getSelectedEntryID,
  getTotalEntriesCount,
} from 'store/entries/entriesSelectors';
import { selectFilter } from 'store/filters/filtersActions';
import { getFilterOptions, getSelectedFilterId, getSelectedFilterLabel } from 'store/filters/filtersSelectors';
import { getAllCopy, getPageTitle, getSubPageIntroText, getShowsPageTitle } from 'store/copy/copySelectors';
import { getBlockItems } from 'store/feature/featureSelectors';
import { getActiveCategoryContentBlockTitle } from 'store/category/categorySelectors';
import { getContentBlockIsFourUp, getPageId, getShowFilterDrop } from 'store/app/appSelectors';

import Entries from 'components/entry/Entries';
import { MoreButton } from 'components/entry/MoreButton';
import FilterDrop from 'components/FilterDrop';
import Loader from 'containers/Loader';
import FeatureBlock from 'components/feature/FeatureBlock';
import { Loading } from 'components/entry/Loading';
// import { Placeholders } from 'components/entry/Placeholders';
import { IntroductionText } from 'components/entry/IntroductionText';
import { Error } from 'components/entry/Error';
import { LOG, SHOW_NAME } from 'constants/App';

class Content extends Component {
  constructor(props) {
    super(props);
    this.handleMoreEntries = this.handleMoreEntries.bind(this);
    this.handleFilterSelect = this.handleFilterSelect.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isInitialised, hasBeenVisible, pageId } = this.props;
    if (!isInitialised && hasBeenVisible) {
      LOG.enabled && console.log(`%cContent.componentDidUpdate | pageId ${pageId}`, LOG.clrBlack);
      this.props.setInitialised();
      this.props.fetchEntries();
    }
  }

  render() {
    const { hasFeatureUnderContent, siteStyles, ageRating, spinner, getShowsPageTitle } = this.props; // passed through
    const { pageId, showFilterDrop } = this.props; // app
    const { isReady, isError, hasNoEntriesToRequest, totalEntriesCount, entries, moreEntriesAvailable } = this.props;
    const { selectedFilterId, selectedFilterLabel, filterOptions, copy, isFetching, title = '' } = this.props;
    const { featureBlocks, introductionText, contentBlockTitle, contentBlockIsFourUp } = this.props;

    const buttonClick = moreEntriesAvailable && !isFetching ? this.handleMoreEntries : null;
    const showMoreLabel = copy[`${pageId}_show_more_txt`] || copy['videos_show_more_txt'] || 'Show more';
    const platform = TITLE_FILTER_REPLACEMENT_IDS.includes(selectedFilterId) ? selectedFilterLabel : '';
    const pageTitle = contentBlockTitle ? contentBlockTitle : title.replace('{platform-name}', platform);
    const sortLabel = copy[`${pageId}_sort_txt`] ? copy[`${pageId}_sort_txt`] : copy[`games_sort_txt`];
    let sortTitle = '';
    switch (pageId) {
      case 'shows':
        sortTitle = copy[`${pageId}_all_${selectedFilterId}`] ? copy[`${pageId}_all_${selectedFilterId}`] : copy['shows_sort_all'];
        break;

      case 'games':
        if (selectedFilterId.replace('-', '') === 'az' || selectedFilterId.replace('-', '') === 'za') {
          sortTitle = copy[`${pageId}_sort_${selectedFilterId.replace('-', '')}_txt`] ? copy[`${pageId}_sort_${selectedFilterId.replace('-', '')}_txt`] : copy['games_all_all_games_txt'];
        } else {
          sortTitle = copy[`${pageId}_all_${selectedFilterId.replace('-', '')}_txt`] ? copy[`${pageId}_all_${selectedFilterId.replace('-', '')}_txt`] : copy['games_all_all_games_txt'];
        }

        break;

      case 'videos':
        sortTitle = copy[`${pageId}_sort_${selectedFilterId.replace('-', '')}_txt`] ? copy[`${pageId}_sort_${selectedFilterId.replace('-', '')}_txt`] : copy['videos_videos_txt'];
        break;

      default:
        sortTitle = copy[`${pageId}_sort_${selectedFilterId.replace('-', '')}_txt`] ? copy[`${pageId}_sort_${selectedFilterId.replace('-', '')}_txt`] : pageTitle;
        break;
    }

    const sortMainTitle = sortTitle ? sortTitle.replace('{show-name}', SHOW_NAME) : '';

    const filterSortLabel = sortLabel;
    const colour = siteStyles.colours.content;
    const wrapClass = `wrapper ${colour} wrapper--radius-top wrapper--radius-bottom`;
    const contentBlockStyle = contentBlockIsFourUp ? 'sortable listing four-up all-items-list' : 'sortable listing three-up all-items-list';
    let renderPlacerHolder = false;
    if (totalEntriesCount > 0) {
      renderPlacerHolder = !isReady ? true : false;
    }
    const ready = isReady || renderPlacerHolder;

    // components
    const moreButton = moreEntriesAvailable ? <MoreButton pageId={pageId} buttonClick={buttonClick} label={showMoreLabel} /> : null;
    const featureBlock = hasFeatureUnderContent ? <FeatureBlock copy={copy} blocks={featureBlocks} siteStyles={siteStyles} ageRating={ageRating} /> : null;
    const filterDrop = showFilterDrop ? (
      <FilterDrop pageId={pageId} filterOptions={filterOptions} filterSortLabel={filterSortLabel} selectedFilterId={selectedFilterId} handleFilterSelect={this.handleFilterSelect} />
    ) : null;
    const introtext = introductionText && introductionText != '' ? <IntroductionText introductionText={introductionText} /> : null;

    // render
    if (hasNoEntriesToRequest) {
      return (
        <div className="filter-section">
          <section className="block-list block-list-filter">
            <div className={wrapClass}>
              <h2 className="cbtitle">{pageTitle}</h2>
            </div>
          </section>
          {introtext}
          {featureBlock}
        </div>
      );
    } else if (isError) {
      return <Error wrapClass={wrapClass} filterDrop={filterDrop} featureBlock={featureBlock} />;
    } else if (!ready) {
      return <Loading spinner={spinner} wrapClass={wrapClass} pageTitle={pageTitle} moreButton={moreButton} featureBlock={featureBlock} contentBlockStyle={contentBlockStyle} />;
      // } else if (renderPlacerHolder) {
      // return <Placeholders wrapClass={wrapClass} pageTitle={pageTitle} totalEntriesCount={totalEntriesCount} moreButton={moreButton} featureBlock={featureBlock} contentBlockStyle={contentBlockStyle} />;
    } else {
      return (
        <div className="filter-section">
          <section className="block-list block-list-filter">
            <div className={wrapClass}>
              {filterDrop}
              <h2 className="cbtitle">{sortMainTitle}</h2>
              <Entries isFetching={isFetching} copy={copy} entries={entries} ageRating={ageRating} contentBlockStyle={contentBlockStyle} />
              {isFetching && <Loader spinner={spinner} isVisible={isFetching} />}
              {moreButton}
            </div>
          </section>
          {introtext}
          {featureBlock}
        </div>
      );
    }
  }

  handleMoreEntries(e) {
    e.preventDefault();
    this.props.batchFetchEntries();
  }

  handleFilterSelect(filterId) {
    this.props.selectFilter(filterId);
  }
}

function mapStateToProps(state) {
  return {
    // app
    pageId: getPageId(state),
    showFilterDrop: getShowFilterDrop(state),
    //
    selectedEntryID: getSelectedEntryID(state),
    copy: getAllCopy(state),
    hasNoEntriesToRequest: getPageHasNoEntriesToRequest(state),
    totalEntriesCount: getTotalEntriesCount(state),
    isInitialised: getIsInitialised(state),
    isReady: getIsReady(state),
    isError: getDidError(state),
    entries: getEntries(state),
    moreEntriesAvailable: getMoreEntriesAvailable(state),
    filterOptions: getFilterOptions(state),
    selectedFilterId: getSelectedFilterId(state),
    selectedFilterLabel: getSelectedFilterLabel(state),
    isFetching: getIsFetchingContentData(state),
    title: getPageTitle(state),
    featureBlocks: getBlockItems(state),
    introductionText: getSubPageIntroText(state),
    // categorys
    contentBlockTitle: getActiveCategoryContentBlockTitle(state),
    contentBlockIsFourUp: getContentBlockIsFourUp(state),
    getShowsPageTitle: getShowsPageTitle(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInitialised: () => {
      dispatch(setInitialised());
    },
    fetchEntries: () => {
      dispatch(fetchEntries());
    },
    batchFetchEntries: () => {
      dispatch(batchFetchEntries());
    },
    selectFilter: (filterId) => {
      dispatch(selectFilter(filterId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Content);
