import React, { Component } from 'react';
import { connect } from 'react-redux';

import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { getBlockItems } from 'store/carousels/carouselsSelectors';
import CarouselItemOther from 'components/carousel/CarouselItemOther';
import CarouselItemApp from 'components/carousel/CarouselItemApp';
import CarouselItemPlaceholder from 'components/carousel/CarouselItemPlaceholder';
import { Title } from 'components/carousel/Title';
import { USE_WEBP } from 'constants/App';

SwiperCore.use([Virtual]);

class CarouselRow extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.onSlideChange = this.onSlideChange.bind(this);
    this.state = {
      activeIndex: 0,
    };
    this.swiper = React.createRef();
  }

  onSlideChange() {
    if (this.swiper) {
      this.setState({ activeIndex: this.swiper.current.swiper.activeIndex });
    }
  }

  next() {
    const { isRTL } = this.props;
    if (this.swiper) {
      isRTL ? this.swiper.current.swiper.slidePrev() : this.swiper.current.swiper.slideNext();
    }
  }
  previous() {
    const { isRTL } = this.props;
    if (this.swiper) {
      isRTL ? this.swiper.current.swiper.slideNext() : this.swiper.current.swiper.slidePrev();
    }
  }

  updateButtons(RTL, blockCount, carouselDisplayCount) {
    let previousClass = 'nav_button nav_left';
    let nextClass = 'nav_button nav_right';
    const { activeIndex } = this.state;
    if (RTL) {
      if (activeIndex === 0) {
        nextClass += ' disabled';
      } else if (activeIndex >= blockCount - carouselDisplayCount) {
        previousClass += ' disabled';
      }
    } else {
      if (activeIndex === 0) {
        previousClass += ' disabled';
      } else if (activeIndex >= blockCount - carouselDisplayCount) {
        nextClass += ' disabled';
      }
    }
    return { previousClass, nextClass };
  }

  renderItems({ blocks, isNewLabel, ageRating, carouselItemStyle }) {
    let blockCount = 0;
    return blocks.map((data, index) => {
      blockCount++;
      if (data.platforms) {
        return (
          <SwiperSlide key={data.id} virtualIndex={index}>
            <CarouselItemApp isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass="swiper-slide" carouselItemStyle={carouselItemStyle} />
          </SwiperSlide>
        );
      } else {
        return (
          <SwiperSlide key={data.id} virtualIndex={index}>
            <CarouselItemOther isNewLabel={isNewLabel} index={index} ageRating={ageRating} {...data} carouselItemClass="swiper-slide" carouselItemStyle={carouselItemStyle} />
          </SwiperSlide>
        );
      }
    });
  }

  render() {
    const CAROUSEL_BREAKPOINT = 601;
    const { isReady, siteStyles, ageRating, rightPadStyle, spinner, isRTL, carouselPlaceholder, carouselBreakpoints } = this.props; // passed through
    const { index, ribbon, title, first, last, blocks, copy, colour, isNewColourBlock, isNewColourBlockEnd } = this.props;
    const { home_new_content_title: isNewLabel } = copy;

    const carouselItemStyle = {};

    const groupColourBlocks = siteStyles.groupColourBlocks;
    const blockColour = siteStyles.colours.carousel === 'cms' ? 'b_' + colour : siteStyles.colours.carousel;
    const extraClass = USE_WEBP ? 'webp' : 'non-webp';

    let clzz = `wrapper ${blockColour}`;
    if (first) {
      clzz += ' wrapper--radius-top--margin-top';
    }
    if (last) {
      clzz += ' wrapper--radius-bottom';
    }
    if (ribbon) {
      clzz += ' wrapper--ribbon';
    }

    let blockClass = `block-list ${colour}`;
    if (index === 0) {
      blockClass += ' block-list--first';
    }
    if (groupColourBlocks) {
      if (isNewColourBlock) {
        blockClass += ' margin_top';
      }
      if (!isNewColourBlockEnd) {
        blockClass += ' same_block_colour';
      }
    }

    const params = {
      spaceBetween: 36,
      slidesPerView: 3,
      loop: false,
      onSlideChange: this.onSlideChange,
    };
    if (isRTL) {
      params.dir = 'rtl';
    }

    const carouselItems = this.renderItems({ blocks, isNewLabel, ageRating, carouselItemStyle });
    const blockCount = carouselItems.length;
    const carouselDisplayCount = window.innerWidth > CAROUSEL_BREAKPOINT ? 3 : 1; // adjust this for mobile
    const hasNavButtons = blockCount > carouselDisplayCount;
    const { previousClass, nextClass } = this.updateButtons(isRTL, blockCount, carouselDisplayCount);

    const wrapperStyle = {};

    const renderCarousel = isReady;

    return !renderCarousel ? (
      <section className={blockClass}>
        <div className={clzz} style={wrapperStyle}>
          {<Title ribbon={ribbon} title={title} extraClass={extraClass} />}
          <div className="carousel_placeholder">
            <CarouselItemPlaceholder spinner={spinner} carouselPlaceholder={carouselPlaceholder} />
          </div>
        </div>
      </section>
    ) : (
      <section className={blockClass}>
        <div className={clzz} style={wrapperStyle}>
          {<Title ribbon={ribbon} title={title} extraClass={extraClass} />}
          <div className="carousel_new">
            <div className="carousel_area">
              <Swiper ref={this.swiper} virtual {...params} breakpoints={carouselBreakpoints}>
                {carouselItems}
              </Swiper>
              {hasNavButtons && (
                <>
                  <div className={previousClass} onClick={this.previous} />
                  <div className={nextClass} onClick={this.next} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    blocks: getBlockItems(state, ownProps.itemsById),
  };
}

export default connect(mapStateToProps, null)(CarouselRow);
